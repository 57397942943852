
import { Vue, Prop, Component } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import { BaseUser } from '@/types/users.types';
import { ApprovalNotification, ApprovalType, Notification } from '@/types/notifications.types';
import ApprovalCard from '@/components/ApprovalCard.vue';
import KeyValueDisplay from '@/components/KeyValueDisplay.vue';
import { EnumsModule } from '@/store/modules/enums.module';
import { sendApproval } from '@/api/registration.api';
import { openErrorSnackbar } from '@/utils/components';
import { TextField } from '@/components/form/form.types';
import GButton from '@/components/gsk-components/GskButton.vue';
import ValidatedFormDialog from '@/components/dialogs/ValidatedFormDialog.vue';
import DraftPreviewWrapper from '@/components/Publish/DraftPreviewWrapper.vue';
import { ListingTypes } from '@/constants';
import { FullListing } from '@/types/listings.types';
import UserCircle from '@/components/UserCircle.vue';
import GDialog from '@/components/gsk-components/GskDialog.vue';
import * as API from '@/api/rpa-admin.api';
import ChosenDependencies from '@/components/projects/ChosenDependencies.vue';
import {
  ApprovalDependency,
  ChecklistBotDetails,
  PromotionChecklistData,
} from '@/types/projects.types';
import ViewChecklist from '@/components/projects/ViewChecklist.vue';

interface BotDetails {
  botName: string;
  botDescription: string;
  businessUnit: string;
  projectOwner: BaseUser;
  configurationIdName: string;
  checklistData: PromotionChecklistData;
  packageId: string;
  botSystem: string;
}

interface KeyValueDisplayField {
  fieldName: string;
  displayName?: string;
}

type NotificationDto = ApprovalNotification | Notification;
const required = true;
@Component({
  components: {
    ApprovalCard,
    KeyValueDisplay,
    GButton,
    ValidatedFormDialog,
    DraftPreviewWrapper,
    UserCircle,
    GDialog,
    ChosenDependencies,
    ViewChecklist,
  },
})
export default class ApprovalDetails extends Vue {
  @Prop({ type: Object, required })
  approval!: ApprovalNotification;
  @Prop({ type: Function, required })
  load!: () => void;

  denyOpen = false;
  responseLoading = false;
  showListing = false;

  openProductionChecklist = false;
  openRpaVerification = false;
  approvalDependencies: ApprovalDependency[] = [];
  selectedApprovals = 0;
  loadingDependencies = false;

  get pendingStatusName() {
    return EnumsModule.enums.status.PENDINGAPPROVAL.name;
  }

  get isListingApproval() {
    return this.approval.approvalRequestTypeMnemonic === 'LISTINGTYPEBUSINESSUNITAPPROVAL';
  }

  get isRpaApproval(): boolean {
    return this.approval.approvalRequestTypeMnemonic === 'BOTPROMOTIONAPPROVAALTYPE';
  }

  get approvalDetails() {
    return this.approval.approvalRequestDetails;
  }

  get checklistDetails(): PromotionChecklistData {
    return (this.approvalDetails as { checklistData: PromotionChecklistData }).checklistData;
  }

  get checklistBotDetails(): ChecklistBotDetails {
    const { botName, botDescription, businessUnit, botSystem } = this.botDetails;
    return { botName, botDescription, businessUnit, botSystem };
  }

  get botDetails(): BotDetails {
    const {
      botName,
      botDescription,
      businessUnit,
      projectOwner,
      configurationIdName,
      checklistData,
      packageId,
      botSystem,
    } = this.approvalDetails as {
      botName: string;
      botDescription: string;
      businessUnit: string;
      projectOwner: BaseUser;
      configurationIdName: string;
      checklistData: PromotionChecklistData;
      packageId: string;
      botSystem: string;
    };
    return {
      botName,
      botDescription,
      businessUnit,
      projectOwner,
      configurationIdName,
      checklistData,
      packageId,
      botSystem,
    };
  }

  showProductionChecklist(): void {
    this.openProductionChecklist = true;
  }

  created(): void {
    if (this.isRpaApproval) {
      this.loadingDependencies = true;
      // call prod dependencies
      const { packageId, botSystem } = this.approvalDetails as { packageId: string; botSystem: string; };
      API.getApprovalDependencies(packageId, botSystem)
        .then(res => {
          this.approvalDependencies = res.dependencies;
          this.selectedApprovals = res.count;
        })
        .finally(() => {
          this.loadingDependencies = false;
        });
    }
  }

  get keyValueFields(): KeyValueDisplayField[] {
    let list: KeyValueDisplayField[] = [];
    const listingTypeId = (this.approval.approvalRequestDetails as FullListing)?.listingTypeId;

    switch (this.approval.approvalRequestTypeMnemonic) {
      case 'LISTINGTYPEBUSINESSUNITAPPROVAL':
        if (listingTypeId === ListingTypes.API) {
          list = [
            { fieldName: 'registrationName', displayName: 'API Name' },
            { fieldName: 'registrationDescription', displayName: 'API Description' },
            { fieldName: 'versionId', displayName: 'API Version' },
            { fieldName: 'projectName', displayName: 'API Project Name' },
            { fieldName: 'projectDescription', displayName: 'API Project Description' },
          ];
        } else {
          list = [{ fieldName: 'listingName' }];
        }

        list = list.concat([
          { fieldName: 'listingDescription' },
          { fieldName: 'listingTypeName' },
          { fieldName: 'isPrivate' },
          { fieldName: 'categoryName' },
          { fieldName: 'businessUnitName' },
        ]);

        break;
      case 'APIOWNERAPPROVAL':
        list = [
          { fieldName: 'projectName' },
          { fieldName: 'projectDescription' },
          { fieldName: 'projectEnvironmentName' },
          { fieldName: 'serviceEnvironmentName' },
        ];
        break;
      case 'APISYSTEMADMINAPPROVAL':
        list = [
          { fieldName: 'projectName' },
          { fieldName: 'projectDescription' },
          { fieldName: 'resourceName' },
          { fieldName: 'resourceDescription' },
          { fieldName: 'ciId' },
          { fieldName: 'businessJustification' },
          { fieldName: 'kong_service_name' },
          { fieldName: 'whiteListGroupName' },
          { fieldName: 'upstream' },
          { fieldName: 'kong_proxy_path' },
          { fieldName: 'timeout' },
          { fieldName: 'hasCustomHeader' },
          { fieldName: 'healthCheckPath' },
          { fieldName: 'healthCheckPattern' },
          { fieldName: 'headers', displayName: 'Header Name' },
          { fieldName: 'authenticationType' },
        ];
        break;
    }
    return list;
  }

  denialModel: [TextField] = [
    {
      type: 'long-text',
      label: 'Explanation',
      key: 'denial',
      value: '',
      required: true,
      validation: {
        rules: 'required',
      },
      attrs: {
        placeholder: 'Why are you denying this request?',
      },
    },
  ];

  get requestUser(): BaseUser {
    const { requestorEmail, requestorFullName, requestorMudId } = this.approval;
    return {
      email: requestorEmail,
      mudId: requestorMudId,
      fullName: requestorFullName,
    };
  }
  get lastApprover() {
    return this.approval.lastApprover;
  }

  get lastApprovalStatusName() {
    return this.approval.lastApprover
      ? EnumsModule.enumsById.status[this.approval.lastApprover?.statusId || -1].name
      : '';
  }

  get approvalStatus() {
    const id = this.approval.approvalStatusId;
    const s = EnumsModule.enums.status;
    const isPending = id === s.PENDINGAPPROVAL.id;
    return {
      isApproved: id === s.APPROVED.id,
      isRejected: id === s.REJECTED.id,
      isPending,
      statusName: this.approval.approvalStatusName,
    };
  }

  deny() {
    const data = this.approval;
    this.responseLoading = true;
    this.doApproval(
      data.approvalRequestId,
      data.approvalRequestTypeMnemonic,
      this.denialModel[0].value,
      EnumsModule.enums.status.REJECTED.id,
      EnumsModule.enums.status.REJECTED.mnemonic,
    );
  }

  cancelRpa(): void {
    this.openRpaVerification = false;
  }

  approveRpa(): void {
    this.openRpaVerification = false;
    const data = this.approval;
    this.doApproval(
      data.approvalRequestId,
      data.approvalRequestTypeMnemonic,
      'Approved',
      EnumsModule.enums.status.APPROVED.id,
      EnumsModule.enums.status.APPROVED.mnemonic,
    );
  }

  approve() {
    if (this.isRpaApproval) {
      // open RPA dialog
      this.openRpaVerification = true;
    } else {
      const data = this.approval;
      this.doApproval(
        data.approvalRequestId,
        data.approvalRequestTypeMnemonic,
        'Approved',
        EnumsModule.enums.status.APPROVED.id,
        EnumsModule.enums.status.APPROVED.mnemonic,
      );
    }
  }

  doApproval(
    id: string | number,
    approvalRequestTypeMnemonic: ApprovalType,
    approvalMessage: string,
    updateStatusId: number,
    updateStatusMnemonic: string,
  ) {
    this.responseLoading = true;
    sendApproval(
      id,
      {
        approvalMessage,
        updateStatusId,
        updateStatusMnemonic,
      },
      approvalRequestTypeMnemonic,
    )
      .then(() => true)
      .catch((err: AxiosError) => {
        openErrorSnackbar.call(this, err.response?.data?.message ?? 'Server Error');
      })
      .then(success => {
        if (success) {
          this.load();
        }
      })
      .finally(() => {
        this.responseLoading = false;
        this.denyOpen = false;
        this.showListing = false;
      });
  }
}
