
import { Component, Vue } from 'vue-property-decorator';
import { Promised } from 'vue-promised';
import { AxiosError } from 'axios';
import DOMPurify from 'dompurify';
import GButton from '@/components/gsk-components/GskButton.vue';
import {
  ApprovalNotification,
  isApprovalNotification,
  Notification,
} from '@/types/notifications.types';
import { getNotificationById, updateNotificationStatus } from '@/api/notifications.api';
import { RouteNames } from '@/constants';
import { EnumsModule } from '@/store/modules/enums.module';
import ValidatedFormDialog from '@/components/dialogs/ValidatedFormDialog.vue';
import ApprovalDetails from '@/components/ApprovalDetails.vue';
import Grid from '@/components/grid/Grid';
import GridCell from '@/components/grid/GridCell';
import { convertDateTime } from '@/utils/time';
import BreadCrumbs from '@/components/BreadCrumbs.vue';
import { UINavigationItem } from '@/types';
import { processDetails } from '@/utils/listings';
import { ListingsModule } from '@/store/modules/listings.module';

type NotificationDto = ApprovalNotification | Notification;

@Component({
  components: {
    GButton,
    Promised,
    ValidatedFormDialog,
    ApprovalDetails,
    Grid,
    GridCell,
    BreadCrumbs,
  },
})
export default class NotificationDetails extends Vue {
  public notification: Promise<NotificationDto> | null = null;
  message = '';

  get breadCrumb(): UINavigationItem[] {
    if (this.$route.params.type === 'requests') {
      return [
        {
          text: 'Requests',
          key: 'requests',
          route: {
            name: RouteNames.NotificationTable,
            params: {
              type: 'requests',
            },
          },
        },
      ];
    }

    return [
      {
        text: 'Updates',
        key: 'updates',
        route: {
          name: RouteNames.NotificationTable,
          params: {
            type: 'notifications',
          },
        },
      },
    ];
  }

  isApproval(notification: NotificationDto) {
    return isApprovalNotification(notification);
  }

  convertDateTime(dateTime: string) {
    return convertDateTime(dateTime, true);
  }

  back() {
    this.$router.safeBack(this.path);
  }

  public get path() {
    return {
      name: RouteNames.NotificationTable,
      params: { type: this.$route.params.type },
    };
  }

  get listingTypes() {
    return ListingsModule.listingIconTypes;
  }

  load() {
    this.notification = getNotificationById(Number(this.$route.params.id))
      .then(r => {
        if (r.data.statusId === EnumsModule.enums.status.NOTIFICATIONUNREAD.id) {
          updateNotificationStatus(
            [r.data.notificationId],
            false,
            EnumsModule.enums.status.NOTIFICATIONREAD.mnemonic,
          );
        }
        this.message = DOMPurify.sanitize(r.data.notificationMessage);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const details: any = r.data.approvalRequestDetails;
        if (details && details.extendedProperties?.details) {
          const typeId = details.listingTypeId;
          const lt = this.listingTypes.find(lt => lt.listingTypeId === typeId);
          if (lt) {
            details.extendedProperties.details = processDetails(
              details.extendedProperties.details,
              lt.listingTemplate,
            );
          } else {
            this.$logger.warn('E209: Could not load listing type...');
          }
        }
        return r.data;
      })
      .catch((e: AxiosError) => {
        // format and re-throw error so vue-promise catches
        if (e && e.response) {
          throw e.response.data.message;
        }
        throw e;
      });
  }

  created() {
    this.load();
  }
}
