
import { Vue, Prop, Component } from 'vue-property-decorator';
import startCase from 'lodash/startCase';
import sortBy from 'lodash/sortBy';
import GridCell from '@/components/grid/GridCell';
import Grid from '@/components/grid/Grid';

export interface KeyValueDisplayField {
  fieldName: string;
  displayName?: string;
}

@Component({
  components: {
    Grid,
    GridCell,
  },
})
export default class KeyValueDisplay extends Vue {
  @Prop({ default: () => ({}) }) data!: Record<string, number | string | null | undefined>;
  @Prop({ default: () => [], type: Array }) filter!: string[];
  @Prop({ default: () => [], type: Array }) fields!: KeyValueDisplayField[];
  @Prop(Boolean) sortKeys!: boolean;

  formatValue(val: string | boolean | number | string[] | null | undefined) {
    if (typeof val === 'string') {
      return val || '';
    } else if (typeof val === 'number') {
      return '' + val;
    } else if (typeof val === 'boolean') {
      return val ? 'Yes' : 'No';
    } else if (Array.isArray(val) && val.every(item => typeof item === 'string')) {
      return val.join(', ');
    }
    return '';
  }

  get formattedData() {
    this.data['ciId'] =
      this.data['serviceNowId'] !== null ? this.data['serviceNowId'] : this.data['pandoraId'];
    const data = this.fields.map(entry => {
      const entryData = this.data[entry.fieldName];

      // Add css classes to object depending on type of entry

      return [
        entry.displayName || startCase(entry.fieldName),
        this.formatValue(entryData),
      ];
    });
    return this.sortKeys ? sortBy(data, d => d[0]) : data;
  }
}
