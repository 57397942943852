
import { Vue, Prop, Component } from 'vue-property-decorator';
import { BaseUser } from '@/types/users.types';
import UserCircle from '@/components/UserCircle.vue';
import { ApprovalStatus, ApprovalType } from '@/types/notifications.types';

const f = (icon: string, type: string) => ({ icon, type });

const required = true;
@Component({
  components: {
    UserCircle,
  },
})
export default class ApprovalCard extends Vue {
  @Prop({ type: String, required }) status!: ApprovalStatus;
  @Prop({ type: String, required }) approvalType!: ApprovalType;
  @Prop({ type: String, required }) statusName!: string;
  @Prop({ type: String, required }) timestamp!: string;
  @Prop({
    type: Object,
    required,
    default: () => ({
      mudId: '',
      email: '',
      fullName: '??? ???',
    }),
  })
  user!: BaseUser;
  @Prop({ type: String }) comment?: string;

  // 6 - APPROVED
  // 10 - REJECTED
  // 12 - PENDINGAPPROVAL
  // 16 - APPROVALPREEMPTED
  // 22 - APPROVALREQUESTCANCELED

  get statusContent(): string {
    const data: Record<ApprovalType, Partial<Record<ApprovalStatus, string>>> = {
      APISYSTEMADMINAPPROVAL: {
        PENDINGAPPROVAL: 'Review requested',
      },
      APIOWNERAPPROVAL: {
        PENDINGAPPROVAL: 'Review requested',
      },
      LISTINGTYPEBUSINESSUNITAPPROVAL: {
        PENDINGAPPROVAL: 'Review requested',
        REJECTED: 'Changes denied',
        APPROVED: 'Changes approved',
      },
      RISKAPPROVAL: {
        PENDINGAPPROVAL: 'Review requested',
      },
      BOTPROMOTIONAPPROVAALTYPE: {
        APPROVED: 'Approved',
        REJECTED: 'Rejected',
      },
    };

    return data[this.approvalType]?.[this.status] ?? this.statusName;
  }

  get hasSlottedContent() {
    return !!this.$slots.default;
  }

  get iconData() {
    return this.statusMap[this.status] ?? { icon: 'warning__circle_filled', type: 'warning' };
  }

  get statusMap(): Record<ApprovalStatus, { icon: string; type: string }> {
    return {
      APPROVED: f('checkmark_filled', 'success'),
      REJECTED: f('error_filled', 'danger'),
      PENDINGAPPROVAL: f('arrow_up', 'secondary'),
      APPROVALPREEMPTED: f('close_filled', 'secondary'),
      APPROVALREQUESTCANCELED: f('close_filled', 'secondary'),
    };
  }

  get formattedTime() {
    const d = new Date(this.timestamp);
    return `${d.toLocaleDateString()} ${d.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    })}`;
  }
}
