
import { Component, Prop, Vue } from 'vue-property-decorator';
import HelpTooltip from '@/components/HelpTooltip.vue';
import Grid from '@/components/grid/Grid';
import GridCell from '@/components/grid/GridCell';
import { ApprovalDependency, RpaBotDependency } from '@/types/projects.types';

const ELLIPSIS_CHARACTER = '\u2026';
const ZERO_LENGTH_SPACE_CHARACTER = '\u200b';

@Component({
  components: {
    Grid,
    GridCell,
    HelpTooltip,
  },
})
export default class ChosenDependencies extends Vue {
  @Prop({ required: false, default: () => [], type: Array })
  readonly dependencies!: ApprovalDependency[];
  @Prop({ required: false, default: false, type: Boolean }) readonly isLoading!: boolean;
  @Prop({ required: false, default: () => [], type: Array }) readonly selected!: string[];
  @Prop({ required: true, default: () => 0, type: Number }) readonly count!: number;

  rowClass(row: ApprovalDependency) {
    return {
      'row--selected': this.isRowSelected(row),
      'row--disabled': this.isRowDisabled(row),
    };
  }

  formatDate(d: string): string {
    const date = new Date(d);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    })}`;
  }

  get selectedApprovals(): number {
    return this.dependencies.filter(d => d.selectedForPromotion).length;
  }

  get selectedLength() {
    return this.selected.length;
  }

  get allSelected() {
    return this.selectedLength === this.dependencies.length;
  }

  get someSelected() {
    return !this.allSelected && !!this.selectedLength;
  }

  isRowDisabled(d: ApprovalDependency): boolean {
    return d.permissionsProblemInProd;
  }

  isRowSelected(d: ApprovalDependency): boolean {
    return d.selectedForPromotion;
  }

  getRowErrorLevel(d: RpaBotDependency): 'danger' | 'warning' | null {
    if (d.versionError) {
      return 'danger';
    }
    if (d.versionIsNotLatest) {
      return 'warning';
    }
    return null;
  }

  getRowErrorText(d: RpaBotDependency): string {
    if (d.versionError) {
      return 'A file version is required.';
    }
    if (d.versionIsNotLatest) {
      return 'A newer version of this file is available.';
    }
    return '';
  }

  // splits a long path into smaller chunks so that wrapping can happen
  splitPath(path = ''): string {
    return path.replace(/\\/g, ZERO_LENGTH_SPACE_CHARACTER + '\\' + ZERO_LENGTH_SPACE_CHARACTER);
  }

  // trims a path to a set length, and adds an ellipsis to the left of the trimmed string.
  // the elllipsys is included in the max length
  trimPath(path: string, maxLength = 35): string {
    if (path.length <= maxLength) {
      return path;
    }
    return (
      ELLIPSIS_CHARACTER +
      path
        .split('')
        .reverse()
        .slice(0, maxLength - 1)
        .reverse()
        .join('')
    );
  }

  versionText(d: RpaBotDependency): string {
    if (d.versionError) {
      return 'None';
    }
    return (d.version || '').toString(); // 0 should be ''
  }

  openVersionLink(url: string): void {
    window.open(url);
  }
}
